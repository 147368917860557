import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdditionalDaysControllerService } from './api/additionalDaysController.service';
import { AuthenticationControllerService } from './api/authenticationController.service';
import { BugControllerService } from './api/bugController.service';
import { CalendarControllerService } from './api/calendarController.service';
import { CloudControllerService } from './api/cloudController.service';
import { CompanyControllerService } from './api/companyController.service';
import { EmployeeControllerService } from './api/employeeController.service';
import { ExcelControllerService } from './api/excelController.service';
import { HolidayControllerService } from './api/holidayController.service';
import { HolidayRequestControllerService } from './api/holidayRequestController.service';
import { LocationControllerService } from './api/locationController.service';
import { NomadControllerService } from './api/nomadController.service';
import { PositionControllerService } from './api/positionController.service';
import { RequestControllerService } from './api/requestController.service';
import { TeamControllerService } from './api/teamController.service';
import { TestScheduleControllerService } from './api/testScheduleController.service';
import { UserControllerService } from './api/userController.service';
import { UserImageControllerService } from './api/userImageController.service';
import { UserPhoneControllerService } from './api/userPhoneController.service';
import { WorkQuotaControllerService } from './api/workQuotaController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
